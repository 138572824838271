import React, { useState, useEffect, useCallback } from 'react'
import { useRecursiveTimeout } from './useRecursiveTimeout'
import { useEmblaCarousel } from 'embla-carousel/react'
import { DotButton, PrevButton, NextButton } from './emblaCarouselButtons'


const EmblaCarouselComponent = ({ autoplay, children, delayLength, loop, slice }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({ loop: loop });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const auto = useCallback(() => {
    if (!embla) return;
    if (!autoplay) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [autoplay, embla]);

  const { play, stop } = useRecursiveTimeout(auto, delayLength);

  const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla.scrollNext(), [embla]);
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [
    embla
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
  }, [embla, onSelect, setScrollSnaps, stop]);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <div className='relative'>
      <EmblaCarouselReact className='overflow-hidden w-full'>
        <div className='flex select-none' aria-live='polite' aria-roledescription='carousel'>
          {children.map((Child, index) => (
            <div className='relative min-w-full' key={index} aria-roledescription='slide' role='group'>
              {Child}
            </div>
          ))}
        </div>
      </EmblaCarouselReact>
      <div className='embla__dots absolute inset-x-0 top-0 md:top-auto md:bottom-0'>
        {scrollSnaps.map((snap, index) => (
          <DotButton
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
            key={index}
          />
        ))}
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default EmblaCarouselComponent;
