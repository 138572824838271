import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent';
import Header from './header'
import Footer from './footer'
import '../styles/default.css'


const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Layout = props => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={data => (
        <div className='antialiased layout bg-gray-100'>
          <Header siteTitle={data.site.siteMetadata.title} navigation={props.navigation}/>
          <main role='main' id='main'>{props.children}</main>
          <Footer navigation={props.navigation} social={props.social} />
          <CookieConsent
            location='bottom'
            buttonText='Accept'
            declineButtonText='Decline'
            disableStyles
            buttonClasses='mx-1 px-4 py-2 inline-block rounded-lg border transform transition duration-150 ease-in-out border-white text-brand-orange-500 bg-white uppercase hover:shadow-sm hover:-translate-y-1 hover:shadow-md'
            declineButtonClasses='mx-1 px-4 py-2 inline-block'
            containerClasses='fixed inset-x-0 text-white bg-brand-orange-500 p-3 text-center'
            contentClasses='inline-block mb-2'
            enableDeclineButton
            cookieName='gatsby-gdpr-google-analytics'>
            Globetrotter CPC Training uses cookies. View our <Link to='/privacy-policy' className='underline'>Privacy Policy</Link> to see how we use these cookies.
          </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navigation: PropTypes.object,
  social: PropTypes.object,
}

export default Layout
