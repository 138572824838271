import React, { PureComponent } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Fade as Hamburger } from 'hamburger-react'

import CheckLink from '../components/checkLink'
import Dropdown from '../components/dropdown'

const headerQuery = graphql`
  {
    logo: file(relativePath: { eq: "globetrotters-logo-aw-02_trimmed.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

class Header extends PureComponent {

  constructor() {
    super()
    this.state = {
      mainNavExpanded: false,
    }
    this.toggleMainNav = this.toggleMainNav.bind(this);
  }
  toggleMainNav() {
    this.setState({
      mainNavExpanded:!this.state.mainNavExpanded
    })
  }
  render() {
    const headerData = this.props.navigation.data
    if(!headerData) return null
    return (
      <StaticQuery
        query={`${headerQuery}`}
        render={data => (
          <header role='banner' className='z-20 top-0 left-0 right-0 text-white font-medium bg-brand-blue-500 text-2xl md:text-base'>
            <nav id='navigation' className='max-w-6xl mx-auto relative flex items-center p-4 md:px-8 justify-between'>
              <Link to='/' className='text-white mr-6 relative z-20 w-20 md:w-32 focus:text-brand-blue-50 focus:outline-none focus:ring'>
                <Img className='w-auto' fluid={data.logo.childImageSharp.fluid} alt={`${headerData.company_display_name} logo`} />
              </Link>
              <span className='md:hidden z-20'>
                <Hamburger
                  color='white'
                  label='Show menu'
                  rounded
                  toggled={this.state.mainNavExpanded}
                  toggle={this.toggleMainNav}
                />
              </span>
              <ul className={`${this.state.mainNavExpanded === false ? ('hidden'):('absolute inset-0 bg-brand-blue-500 flex flex-col h-screen justify-start pt-32 z-10')} md:flex md:items-center`}>
                {headerData.nav.map((nav, index) => {
                  return (
                    <React.Fragment key={`nav-${index}`}>
                      {nav.items.length > 1 ? (
                        <Dropdown items={nav.items}>{nav.primary.label}</Dropdown>
                      ):(
                        <li key={`nav-${index}`} className='m-2 text-black'>
                            <CheckLink className='block py-2 px-3 text-center border-b border-transparent text-white hover:text-brand-blue-50 hover:border-brand-orange-500 focus:text-brand-blue-50 focus:outline-none focus:ring focus:border-brand-orange-200 transition duration-150 ease-in-out' activeClassName='border-brand-orange-500' link={nav.primary.link} onClick={ this.toggleNav }>{nav.primary.label}</CheckLink>
                        </li>
                      )}
                    </React.Fragment>
                  )
                })}
              </ul>
            </nav>
          </header>
        )}
      />
    )
  }
}

export default Header
