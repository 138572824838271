import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import CheckLink from '../components/checkLink'
import SEO from '../components/seo'
import SliceList from '../components/sliceList'
import { withPreview } from 'gatsby-source-prismic'

export const query = graphql`
  query Page($id: String, $lang: String!) {
    prismicNavigation(lang: { eq: $lang }) {
      ...PrismicNavigationFragment
    }
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
    prismicSocial(lang: { eq: $lang }) {
      ...PrismicSocialFragment
    }
  }
`

const PageTemplate = ({ data }) => {
  const pageData = data.prismicPage.data
  const navigation = data.prismicNavigation
  const social = data.prismicSocial
  return (
    <Layout navigation={navigation} social={social}>
      <SEO title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <div className='text-gray-800 text-lg relative'>
        <div className={`flex flex-col ${pageData.page_image.fluid && ('hero')} justify-center relative`}>
          {pageData.page_image.fluid ? (
            <>
              <div className='absolute inset-0 '>
                <Img className='object-center object-cover h-full overflow-hidden' fluid={pageData.page_image.fluid} alt={pageData.page_image.alt} />
              </div>
              <div className='relative max-w-6xl mx-auto px-3 sm:px-0 w-full'>
                <div className='md:w-1/2'>
                  <div className='text-white p-6 z-10 relative'>
                    {pageData.page_title && <div className='uppercase' dangerouslySetInnerHTML={{__html:pageData.page_title.html}} />}
                    {pageData.page_text.html && <div className='my-4' dangerouslySetInnerHTML={{__html:pageData.page_text.html}} />}
                    {(pageData.page_button_link && pageData.page_button_text) && <CheckLink className='button button-lg mt-3' link={pageData.page_button_link}>{pageData.page_button_text}</CheckLink>}
                  </div>
                  <div className='bg-brand-blue-500 opacity-50 absolute inset-0 z-0 md:w-1/2' />
                </div>
              </div>
            </>
          ):(
            <div className='relative max-w-6xl mx-auto w-full p-6'>
              {pageData.page_title && <div className='uppercase' dangerouslySetInnerHTML={{__html:pageData.page_title.html}} />}
            </div>
          )}
        </div>
        {pageData.body !== null && pageData.body.length > 0 && pageData.body.map((slice, index) => {
          return (
            <SliceList slice={slice} key={`slice-${index}`} />
          )
        })}
      </div>
    </Layout>
  )
}

export default withPreview(PageTemplate)
