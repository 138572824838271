import React from 'react'
import PropTypes from 'prop-types'
import Accordion from './accordion'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Counter from './counter'
import Hero from './hero'
import Image from './image'
import Map from './map'
import Quote from './quote'
import Text from './text'
import Video from './video'

const SliceList = ({ slice }) => {
  return (
    <>
      {slice.slice_type === 'accordion' && (
        <div className='max-w-6xl w-full mx-auto px-3 sm:px-6'>
          <Accordion slice={slice} />
        </div>
      )}
      {slice.slice_type === 'banner_with_caption' && (
        <div className='bg-brand-orange-500'>
          <div className='max-w-6xl w-full mx-auto'>
            <BannerWithCaption slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'card_deck' && (
        <div className='max-w-6xl mx-auto py-4 sm:py-8 px-1 sm:px-0'>
          <CardDeck slice={slice} />
        </div>
      )}
      {slice.slice_type === 'contact' && (
        <div className='max-w-6xl mx-auto'>
          <div className='max-w-3xl px-6 mb-6'>
            <Contact slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'counter' && (
        <div className='max-w-6xl mx-auto px-3 sm:px-6'>
          <Counter slice={slice} />
        </div>
      )}
      {slice.slice_type === 'hero' && (
        <Hero slice={slice} />
      )}
      {slice.slice_type === 'image_gallery' && (
        <div className='bg-white'>
          <div className='max-w-4xl w-full mx-auto p-1 sm:px-0'>
            <Image autoplay delayLength={6000} slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'map' && (
        <div className='max-w-4xl mx-auto px-3 sm:px-6'>
          <Map slice={slice} />
        </div>
      )}
      {slice.slice_type === 'quote' && (
        <div className='quote'>
          <div className='max-w-6xl mx-auto px-8 sm:px-6 md:flex md:flex-col py-12'>
            <Quote slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'text' && (
        <Text slice={slice} />
      )}
      {slice.slice_type === 'video' && (
        <div className='max-w-4xl w-full mx-auto p-1 sm:px-0'>
          <Video slice={slice} />
        </div>
      )}
    </>
  )
}

SliceList.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default SliceList
