import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import CheckLink from './checkLink'


const Text = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} ${slice.primary.blue_background && ('blue')} p-6 sm:max-w-6xl w-full mx-auto`}>
      <span className={`${slice.primary.blue_background && ('prose-blue')} prose prose-sm sm:prose lg:prose-lg`} dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
      {(slice.primary.button_link.url && slice.primary.button_text) && <div className='text-center mt-6'><CheckLink className='button' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink></div>}
    </div>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Text
