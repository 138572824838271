import React from 'react'
import CheckLink from '../components/checkLink'
import Social from '../components/social'

export const Footer = (props) => {
  const footerData = props.navigation.data
  const socialData = props.social.data
  if(!footerData || !socialData) return null
  return (
    <footer role='contentinfo' className='mt-auto bg-gray-900 text-gray-50'>
      <div className='mx-auto max-w-6xl px-6 py-10 grid gap-6 grid-cols-1 md:grid-cols-3'>
        <div>
          <div className='text-lg font-medium'>{footerData.company_display_name}</div>
          <div className='my-4'><span className='text-gray-400'>Telephone: </span><a className='hover:underline focus:outline-none focus:ring' href={`tel:${footerData.company_phone}`}>{footerData.company_phone}</a></div>
          <div className='my-4'><span className='text-gray-400'>Email: </span><a className='hover:underline focus:outline-none focus:ring' href={`mailto:${footerData.company_email}`}>{footerData.company_email}</a></div>
          <Social networks={socialData} />
        </div>
        {footerData.body.map((footer, index) => {
          return (
            <div key={`footer-${index}`}>
              <div className='uppercase text-gray-400' dangerouslySetInnerHTML={{__html:footer.primary.title}} />
              <ul>
              {footer.items.map((footerItem, index) => {
                return (
                  <li className='my-2' key={`footerItem-${index}`}>
                    <CheckLink className='py-2 hover:underline focus:outline-none focus:ring' link={footerItem.link}>{footerItem.link_label}</CheckLink>
                  </li>
                )
              })}
              </ul>
            </div>
          )
        })}
      </div>
      <div className='text-center py-4 text-gray-400'>{footerData.company_display_name} <span className='text-yellow-500'>&copy;</span> <span>{new Date().getFullYear()}</span></div>
    </footer>
  )
}

export default Footer
