import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'


const BannerWithCaption = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} relative overflow-hidden`}>
      <div className='pb-8 sm:pb-12 md:pb-20 lg:max-w-2xl lg:w-1/2 lg:pb-20'>
        <div className='mt-10 mx-auto max-w-screen-xl px-6 sm:mt-12 md:mt-16 lg:mt-16 lg:text-left xl:mt-20'>
          {slice.primary.title.html && (
            <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
          )}
          {slice.primary.text.html && (
            <div className='my-4 prose lg:prose-lg xl:prose-xl prose-blue' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
          )}
          {slice.primary.button_link.url && (
            <div className='mt-8'>
              <div className='inline-flex'>
                <CheckLink className='button text-black focus:outline-none focus:ring' link={slice.primary.button_link}>
                  {slice.primary.button_text}
                </CheckLink>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
        <Img fluid={slice.primary.image.fluid} alt={slice.primary.image.alt}/>
      </div>
    </div>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
