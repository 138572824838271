import React from 'react'
import PropTypes from 'prop-types'
import Card from './card'

const CardDeck = ({ slice }) => {
  var numberOfCards = slice.items.length
  return (
    <ul className={`component-${slice.slice_type} my-5 grid grid-cols-1 md:gap-1 ${(numberOfCards % 2 === 0) ? ('md:grid-cols-2 xl:grid-cols-4'):('md:grid-cols-3')}`}>
      {slice.items.map((card, index) => {
        return (
          <Card fields={card} key={`card-${index}`} />
        )
      })}
    </ul>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CardDeck
