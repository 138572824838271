import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const Card = ({ fields }) => {
  return (
    <li className='component-card flex flex-col flex-grow relative text-center sm:m-2 text-white'>
      {get(fields.image, 'fluid') && (
        <div className='overflow-hidden transition duration-500 transform'>
          <Img fluid={fields.image.fluid} alt={fields.image.alt} />
        </div>
      )}
      <div className='flex flex-col flex-grow m-4 md:m-6 justify-center'>
        <div dangerouslySetInnerHTML={{__html:get(fields, 'title.html')}} />
        <div className='my-3'>
          <div dangerouslySetInnerHTML={{__html:get(fields, 'text.html')}} />
        </div>
        {fields.button_link.url && (
          <CheckLink className='link mt-auto py-2 px-4 md:py-3 md:px-8 bg-white border border-transparent rounded-sm uppercase transform transition duration-150 ease-in-out hover:-translate-y-1 hover:shadow-md focus:outline-none focus:ring' link={fields.button_link}>{fields.button_text}</CheckLink>
        )}
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired,
}

export default Card
