import React from 'react'


const Contact = () => {
  return (
    <form
      name='contact'
      method='POST'
      action='/success'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
    >
      <input type='hidden' name='bot-field' />
      <input type='hidden' name='form-name' value='contact' />
      <div className='mb-4'>
        <label className='block text-gray-900 font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='name'>What's your name?</label>
        <input className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' type='text' name='name' id='name' required />
      </div>
      <div className='mb-4'>
        <label className='block text-gray-900 font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='email'>What's your email address?</label>
        <input className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' type='email' name='email' id='email' required />
      </div>
      <div className='mb-4'>
        <label className='block text-gray-900 font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='phone'>What's your phone number?</label>
        <input className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:ring placeholder-gray-500' type='tel' name='phone' id='phone' placeholder='Optional' />
      </div>
      <div className='mb-4'>
        <label className='block text-gray-900 font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='message'>How can we help?</label>
        <textarea rows='4' className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' name='message' id='message' required></textarea>
      </div>
      <div className='mb-4 block sm:inline-block'>
        <button type='submit' className='button w-full sm:w-auto focus:outline-none focus:ring'>Send your enquiry</button>
      </div>
    </form>
  )
}

export default Contact
