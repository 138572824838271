import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const Hero = ({ author, publishDate, slice }) => {
  return (
    <div className={`component-${slice.slice_type} bg-white bg-cover bg-no-repeat overflow-hidden relative`}>
      <div className='container mx-auto md:flex flex-row'>
        <div className='content pt-20 w-full flex-1'>
          <div className='pb-8 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32 text-center lg:text-left'>
            <div className='mt-10 mx-auto px-6 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28'>
            <div className='bg-clip-text bg-gradient-to-r font-black md:text-6xl text-5xl text-transparent from-indigo-600 to-blue-500 tracking-tight inline-block' itemProp='name'>
                {get(slice.primary, 'title.text')}
              </div>
              {get(slice.primary, 'text') && (
                <div className='sm:mx-auto lg:mx-0 my-4' itemProp='headline'>
                  <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
                </div>
              )}
              {(slice.primary.button_link && slice.primary.button_text) && (<CheckLink className='button mt-3' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>)}
            </div>
          </div>
        </div>
        {get(slice.primary.image, 'fluid') && (
          <div className='flex-1'>
            <div className='polygon md:absolute right-0 top-0 bottom-0 md:w-1/2'>
              <Img fluid={slice.primary.image.fluid} alt={slice.primary.image.alt} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
  publishDate: PropTypes.string,
  author: PropTypes.object
}

Hero.defaultProps = {
  publishDate: null,
  author: null,
}

export default Hero
